<template>
    <div class="desktopLayoutClass">
        <v-app>
            

            <div>
                <!-- Header -->
                <div class="d-flex header" style="position: sticky; top: 0; z-index: 3; background: white;" v-show="show_header">
                    <div style="display: flex; flex-direction: row; width: 100%">
                        <div style="width: 10%; padding-top: 6px" align="left" v-on:click="redirectToProfilePage()">
                            <img src="https://img.icons8.com/material-outlined/20/000000/left.png"/>
                        </div>
                        <div style="width: 90%" align="left">
                            <span style="font-weight: 600; font-size: 15pt">{{ $t("Customer.EditProfile.My_Profile") }}</span>
                        </div>
                    </div>
                </div>
                <!-- Header -->

                <div>
                    <div v-if="isLoading" style="margin-top: 5%;">
                        <div class="px-2 pb-4" align="left">
                            <v-skeleton-loader class="py-2" type="heading"></v-skeleton-loader>
                            <v-skeleton-loader class="py-2" type="heading" width="180vw"></v-skeleton-loader>
                        </div>

                        <div class="px-2 py-4" align="left">
                            <v-skeleton-loader class="py-2" type="heading"></v-skeleton-loader>
                            <v-skeleton-loader class="py-2" type="heading" width="180vw"></v-skeleton-loader>
                        </div>

                        <div class="px-2 py-4" align="left">
                            <v-skeleton-loader class="py-2" type="heading"></v-skeleton-loader>
                            <v-skeleton-loader class="py-2" type="heading" width="180vw"></v-skeleton-loader>
                        </div>

                        <div class="px-2 py-4" align="left">
                            <v-skeleton-loader class="py-2" type="heading"></v-skeleton-loader>
                            <v-skeleton-loader class="py-2" type="heading" width="180vw"></v-skeleton-loader>
                        </div>
                        
                        <div class="px-2 py-4" align="left">
                            <v-skeleton-loader class="py-2" type="heading"></v-skeleton-loader>
                            <v-skeleton-loader class="py-2" type="heading" width="180vw"></v-skeleton-loader>
                        </div>

                        <div class="px-2 py-4" align="left">
                            <v-skeleton-loader class="py-2" type="heading"></v-skeleton-loader>
                            <v-skeleton-loader class="py-2" type="heading" width="180vw"></v-skeleton-loader>
                        </div>
                    </div>
                    <div v-else style="margin-top: 12%;">
                        <div v-if="isKBZPayUser" class="pt-4">
                            <editProfileAppUserComponent :customer_data="editUserProps"></editProfileAppUserComponent>
                        </div>
                        <div v-if="!isKBZPayUser" class="pt-4">
                            <editProfileBrowserUserComponent :customer_data="editUserProps"></editProfileBrowserUserComponent>
                        </div>
                    </div>
                </div>
            </div>
        </v-app>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
    name: "EditUserProfile",
    components: {
        banner: () => import('../../../components/bannersComponent.vue'),
        bottomNavBar: () => import('./bottomnavbar.vue'),
        HorizontalScroll: () => import('vue-horizontal-scroll'),
        NavBar: () => import('../../../views/navbar.vue'),
        editProfileAppUserComponent: () => import('../../../components/editProfileAppUserComponent.vue'),
        editProfileBrowserUserComponent: () => import('../../../components/editProfileBrowserUserComponent.vue')
    },
    data() {
        return {
            auth_error_enable: false,
            error_enable: false,
            isLoading: true,
            isMobile: '',
            show_header: true,
            editUserProps: null,
            currentCustomer: '',
            customerName: '',
            mobileNumber: '',
            editEmail: '',
            editHeight : '',
			editWeight : null,
			editDob : '',
			editGender:'',
			editSmoking : '',
			editAlcoholic : '',
			editNrc: '',
            heightInFeet: '',
            heightInInch: '',
            isKBZPayUser: null,
            genderList :[{"key":"male","mm_text":"ကျား","en_text":"Male"},{"key":"female","mm_text":"မ","en_text":"Female"},{"key":"other","mm_text":"အခြား","en_text":"Other"}],
			alcoholicList : [{"key":"non_drinker","mm_text":"အရက်လုံးဝမသောက်ပါ","en_text":"Non drinker"},{"key":"social_drinker","mm_text":"လူမှုရေးအရသောက်ပါသည်","en_text":"Social drinker"},{"key":"moderate_drinker","mm_text":"အလွန်အကြူးမသောက်ပါ","en_text":"Moderate drinker"},{"key":"alcoholic","mm_text":"အရက်စွဲနေပါသည်","en_text":"Alcoholic"}],
			smokerList :  [{"key":"yes","mm_text":"သောက်သည်","en_text":"Yes"},{"key":"no","mm_text":"မသောက်ပါ","en_text":"No"}],
        };
    },
    mounted() {
        this.$el.addEventListener('click', this.onClick);
        this.$i18n.locale = this.$store.state.locale;

        let userAgent = navigator.userAgent;
        if(userAgent.includes('kbzpay')){
            // this.isKbzApp = false;
            this.show_header = false;
        }

        if (window.xm){
            document.title = '';
        } else {
            if(this.$store.state.locale == 'en')
                document.title = 'Edit Profile';
            else
                document.title = 'ပရိုဖိုင်ကိုပြင်ဆင်ခြင်း'
        }

        this.customerName = this.$store.state.name;

        this.isMobile = this.$device.mobile;
        this.currentCustomer = this.$cookies.get('customerToken');
        if (!this.currentCustomer) {
            this.$router.push({
                name: 'Login',
            });
        } else {
            let x =  localStorage.getItem('currentURL');
            let pageName = 'UserProfileScreen';
            if( x == pageName) {
                x = '';
            }
            localStorage.setItem('currentURL', pageName);
            let pageAccessBody = {
                token: this.currentCustomer,
                pageName: pageName,
                previousPage: x ? x : 'START'
            }
            axios.post(process.env.VUE_APP_BACKEND_URL + '/logUserAPIInvocations', pageAccessBody);

            let editProfilePageData = {
                token: this.currentCustomer,
                typeOfUser: 'CUSTOMER',
            };
            axios.post(process.env.VUE_APP_BACKEND_URL + '/editProfilePage', editProfilePageData).then((editProfilePageDataResponse) => {
                // this.isKBZPayUser = true;
                this.customerName = editProfilePageDataResponse.data.data.customer_name;
                this.mobileNumber = this.customer_name == 'Guest' ? '' : editProfilePageDataResponse.data.data.phoneNumber;
                this.editEmail = editProfilePageDataResponse.data.data.email;
                this.heightInFeet = editProfilePageDataResponse.data.data.customer_height ?editProfilePageDataResponse.data.data.customer_height.split("\'")[0] != "0" ? editProfilePageDataResponse.data.data.customer_height.split("\'")[0] : "" : "";
				this.heightInInch = editProfilePageDataResponse.data.data.customer_height ? editProfilePageDataResponse.data.data.customer_height.split("\'")[1].split("\"")[0] != "0" ? editProfilePageDataResponse.data.data.customer_height.split("\'")[1].split("\"")[0] : "" : "";
				this.editHeight = this.heightInFeet +"\'"+ this.heightInInch + "\"";
				this.editWeight = editProfilePageDataResponse.data.data.customer_weight;
				if (editProfilePageDataResponse.data.data.customer_dob) {
					this.editDob = new Date(editProfilePageDataResponse.data.data.customer_dob).toISOString().split('T')[0];
                }
                let customerGender = editProfilePageDataResponse.data.data.customer_gender;
                if (customerGender) {
					let gender = this.genderList.find(o => o.key === customerGender.replace(" ","_").toLowerCase());
                    this.editGender = gender;
				}
				this.editNrc = editProfilePageDataResponse.data.data.NRC;
                if (editProfilePageDataResponse.data.data.alcoholic) {
                    let alcoholic = editProfilePageDataResponse.data.data.alcoholic;
					this.editAlcoholic = this.alcoholicList.find(o => o.key === alcoholic.replace(" ","_").toLowerCase());
				}
				if (editProfilePageDataResponse.data.data.smoker) {
                    let smokerValue = editProfilePageDataResponse.data.data.smoker;
					this.editSmoking = this.smokerList.find(o => o.key === smokerValue.replace(" ","_").toLowerCase());
				}

                this.editUserProps = {
                    token: this.currentCustomer,
                    customer_name: this.customerName,
                    mobile_number: this.mobileNumber,
                    email: this.editEmail,
                    heightInFeet: this.heightInFeet,
                    heightInInch: this.heightInInch,
                    weight: this.editWeight,
                    dob: this.editDob,
                    gender: this.editGender,
                    NRC: this.editNrc,
                    alcoholic: this.editAlcoholic,
                    smoker: this.editSmoking
                };
                this.isKBZPayUser = editProfilePageDataResponse.data.data.kbzpayAppUser;
                // this.isKBZPayUser = true;
                this.isLoading = false;
                this.userPageViewTable();
            }).catch((editProfilePageDataError) => {
                console.log(editProfilePageDataError);
                if (editProfilePageDataError.response.status == 401) {
                    this.auth_error_enable = true;
                } else {
                    this.error_enable = true;
                }
            });
        }
    },
    methods: {
        closeDialogUnauthorized() {
            this.auth_error_enable = false;
            this.$router.push({
                name: "Login",
            });
        },
        closeDialogError() {
            this.error_enable = false;
            this.$router.push({
				name: "UserProfile",
			});
        },
         redirectToProfilePage() {
			this.$router.go(-1);
		},
        userPageViewTable() {
            let userPageViewTableBody;
            if (this.currentCustomer === null) {
                userPageViewTableBody = {
                    pageName : 'EditUserProfile',
                    typeOfUser: 'CUSTOMER'
                };
            } else {
                userPageViewTableBody = {
                    pageName: 'EditUserProfile',
                    typeOfUser: 'CUSTOMER',
                    token: this.currentCustomer
                };
            }
            axios.post(process.env.VUE_APP_BACKEND_URL + "/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
    },
    }
}
</script>

<style scoped>

</style>